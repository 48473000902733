import React, { useState, useRef, useEffect } from "react"
import "./Nav.scss"
import { Link } from "gatsby"
import navLogo from "../../img/navLogo.svg"

interface NavProps {
  className?: string
  id?: string
  children?: ReactChild | ReactChild[]
}

const Nav = ({
  className,
  id,
  children
  } : NavProps) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const handleMenu = () => {
    setIsMenuOpen(prev => !prev)
  }

  return (
    <section className="nav">
      <div className="section__wrap">
        <Link className="nav__logo__wrapper" to="/"><img className="nav__logo" src={navLogo} /></Link>
        <ul className={`${isMenuOpen ? "open" : ""}`}>
          <li className={`${isActive ? "active" : ""}`}><Link to="/">Home</Link></li>
          <li className="temporary"><Link to="">Our Story</Link></li>
          <li ><Link to="/programs">Programs</Link></li>
          <li ><Link to="/podcast">Podcast</Link></li>
          <li ><Link to="/masterminds">Masterminds</Link></li>
          <li className={`${isActive ? "active" : ""}`}><Link to="/testimonials">Success Stories</Link></li>
          <a className="button secondary" href="https://www.7days7figures.com/join" target="blank">Get Started</a>
        </ul>
        <p id="menuToggle" onClick={handleMenu}>menu</p>
      </div>
    </section>
  )
};

export default Nav;
