import * as React from "react"
import "../App.scss";
import Section from "../components/Section/Section";
import Row from "../components/Row/Row";
import Col from "../components/Col/Col";
import CtaBar from "../components/CtaBar/CtaBar";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";

import PodcastIco from "../img/podcastIco.svg"
import PodcastYoutube from "../img/podcastsYoutube.svg"
import PodcastApple from "../img/podcastsApple.svg"
import PodcastSpotify from "../img/podcastsSpotify.svg"

// data

// markup
const Podcast = () => {
  return (
    <main>
      <Nav />
      <Section className="hero heroLow" id="hero">
        <Row>

          <Col size="50">
            <h1>Mindtrepreneur podcast</h1>
          </Col>
          <Col size="50" className="hero__button">
          </Col>
        </Row>
      </Section>

      <Section className="podcasts">

        <Row>

          <Col size="33">
            <a href="https://www.youtube.com/channel/UC8b9yu1SVeOX9uyc7Z1Q7nQ" className="podcasts__item">
              <img className="podcasts__item__icon" src={PodcastIco} alt="Podcast Mindtrepreneur" />
              <div className="podcasts__item__pulse"><img className="podcasts__item__platform" src={PodcastYoutube} alt="Podcast Mindtrepreneur" /></div>
              <h3>Watch on YouTube</h3>
            </a>
          </Col>

          <Col size="33">
            <a href="https://podcasts.apple.com/cz/podcast/mindtrepreneur-podcast/id1647941653" className="podcasts__item">
              <img className="podcasts__item__icon" src={PodcastIco} alt="Podcast Mindtrepreneur" />
              <div className="podcasts__item__pulse"><img className="podcasts__item__platform" src={PodcastApple} alt="Podcast Mindtrepreneur" /></div>
              <h3>Listen on Apple Podcasts</h3>
            </a>
          </Col>

          <Col size="33">
            <a href="https://open.spotify.com/show/6w6WPnyeslxs02b8jlDQaJ?si=ef9050f4271d4eb8" className="podcasts__item">
              <img className="podcasts__item__icon" src={PodcastIco} alt="Podcast Mindtrepreneur" />
              <div className="podcasts__item__pulse"><img className="podcasts__item__platform" src={PodcastSpotify} alt="Podcast Mindtrepreneur" /></div>
              <h3>Listen on Spotify</h3>
            </a>
          </Col>
        </Row>

      </Section>

      <CtaBar
        title="Ready to Transform Your Life & Business?"
        linkText="Start now!"
        link="https://www.7days7figures.com/join"
      />


      <Footer/>

    </main>
  )
}

export default Podcast
