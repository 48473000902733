import React, { ReactChild, ReactChildren } from "react";
import { Link } from "gatsby"
import Row, { RowProps } from "../Row/Row";
import Col, { ColProps } from "../Col/Col";
import navLogo from "../../img/navLogo.svg"
import "./Footer.scss"

interface FooterProps {
  className?: string
  id?: string
  colFlex?: boolean
  reversed?: boolean
}

const Footer = ({
  className,
  id,
  colFlex,
  reversed
} : FooterProps) => {

  return (
    <footer className="footer">
      <div className="section__wrap">
        <Row>
            <Col size="70">
              <Link to="/">Home</Link>
              <Link to="/programs">Programs</Link>
              <Link to="/podcast">Podcast</Link>
              <Link to="/masterminds">Masterminds</Link>
              <Link to="/testimonials">Success Stories</Link>
            </Col>
            <Col size="30">
              <img className="nav__logo" src={navLogo} />
            </Col>
        </Row>
        <Row>
          <Col className="temporary" size="25">
            <a href="/">Facebook</a>
            <a href="/">Instagram</a>
            <a href="/">YouTube</a>
            <a href="/">Twitter</a>
          </Col>
          <Col className="temporary" size="25">
            <Link to="/terms-and-conditions">Terms & conditions</Link>
            <Link to="/privacy-policy">Privacy policy</Link>
            <Link to="/disclaimer">Disclaimer</Link>
            <Link to="/return-exchange">Return exchange</Link>
          </Col>
          <Col size="25">
          </Col>
        </Row>
      </div>
    </footer>
  )
};

export default Footer;
